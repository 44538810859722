import React from "react";
// import { KTRVHeader, KTRVFooter } from "features/organisms/";
import { LeftMenu, OAOLine, KTRVFooter } from "features/organisms";
import "./styles.scss";
import { useStore } from "effector-react";
import { $files } from "features/common/model";
import { getFiles } from "features/common/api";

export const Documents = React.memo(() => {
    const [year, setYear] = React.useState("");
    const files = useStore($files);
    
    React.useEffect(() => {
        // console.log("MOUNT 2");
        getFiles();
    }, []);

    React.useEffect(() => {
        const year = new Date().getUTCFullYear();
        // console.log("FOLDER 2", files, files.folder, files.folder[0]);

        if (files.folders) {
            console.log("FOLDER", files.folders, Object.keys(files.folders));
            const lastYear = Object.keys(files.folders).reverse()[0];

            // console.log("FOLDER 2", lastYear, Object.keys(files.folders).reverse(), year.toString());

            if (Object.keys(files.folders).length > 0)
                setYear(Object.keys(files.folders).includes(year) ? year.toString() : lastYear);

            // console.log(files.folders[year.toString()], year);
            console.log("AAA", files.folders.length > 0, Object.keys(files.folders).includes(year), year.toString(), lastYear);
        }
    }, [files]);
    

    // console.log("FILES => ", files);
    console.log("YEAR", year);

    // const files = {
    //     "2015": [
    //         {name: "Test.zip", filesize: "17.3 КБ"},
    //         {name: "Test.rar", filesize: "17.3 КБ"},
    //         {name: "Test.doc", filesize: "17.3 КБ"},
    //     ],
    //     "2016": [
    //         {name: "Test.zip", filesize: "17.3 КБ"},
    //         {name: "Test.rar", filesize: "17.3 КБ"},
    //         {name: "Test.doc", filesize: "17.3 КБ"},
    //     ],
    //     "2020": [
    //         {name: "Test.zip", filesize: "17.3 КБ"},
    //         {name: "Test.doc", filesize: "17.3 КБ"},
    //         {name: "Test.docx", filesize: "17.3 КБ"},
    //         {name: "Test.xls", filesize: "17.3 КБ"},
    //         {name: "Test.xlsx", filesize: "17.3 КБ"},
    //     ],
    //     "Другое": [
    //         {name: "Test.zip", filesize: "17.3 КБ"},
    //         {name: "Test.rar", filesize: "17.3 КБ"},
    //         {name: "Test.doc", filesize: "17.3 КБ"},
    //     ],
    //     "_Сертификаты": [
    //         {name: "sky.jpg", filesize: "17.3 КБ"},
    //         {name: "sky.jpg", filesize: "17.3 КБ"},
    //         {name: "sky.jpg", filesize: "17.3 КБ"},
    //     ]
    // };

    const DocsElem = ({ i, file, type, year = null }) => {
        const fileUrl = 
            type === "licences"
                ? `https://711arz.ru/api/file.php?type=${type}&file=${i+1}`
                : `https://711arz.ru/api/file.php?type=${type}&file=${i+1}&year=${year}`;

        return (
            <div
                className="docsElem"
                id={`id${i}`}
                // onClick={() => window.open(file.url, '_blank')}
                onClick={() => window.open(fileUrl, '_blank')}
                key={i}
                onLoad={e => {
                    // // console.log(e.target.parentNode)
                    // const block = e.target.parentNode;
                    // // block.setAttribute("id", "");
                    // window.timeoutList.map(item => clearTimeout(item));
                    
                    // setTimeout(() => {
                    //     block.setAttribute("id", "animate");
                    // }, i*150);
                }}
                >
                <img src={`/assets/icons/${file.name.split(".")[file.name.split(".").length - 1].toLowerCase()}.png`} alt="" />
                <div className="docsInfo">
                    <p
                        style={
                            file.name.length > 60 ? {
                                fontSize: "10px"
                            } : file.name.length > 40 ? {
                                fontSize: "12px"
                            } : {}
                        }
                    >
                        { file.name.split(".").slice(0, file.name.split(".").length - 1).join(".") }
                    </p>
                    <span>{file.filesize}</span>
                </div>
            </div>
        )
    }

    return (
        <div className="contentInfo">
            <OAOLine/>
            <div className="contentData">
                <LeftMenu elems={[
                    {name: "Лицензии и сертификаты", url: "#licences"}
                ]}/>
                <div className="data">
                    <div>
                        <p className="blockName">ДОКУМЕНТЫ</p>

                        <span className="docsYearsText">Сортировка по годам</span>
                        <div className="docsYears">
                            {
                                files.folders ? Object.keys(files.folders).filter(item => item[0] !== "_").map((item, key) => {
                                    return (
                                        <p
                                            onClick={() => {
                                                window.timeoutList.map(item => clearTimeout(item));
                                                setYear(item);
                                            }}
                                            key={key}
                                            className={year === item ? "active" : null}
                                        >{item}</p>
                                    )
                                }) : null
                            }
                        </div>
                        
                        <div className="docsList">
                            {
                                files.folders && year ? files.folders[year].length > 0 ? files.folders[year].map((file, i) => {
                                    return (
                                        <DocsElem i={i} file={file} type={"docs"} year={year} />
                                    )
                                }) : <div>Ничего не найдено</div>
                                : <div>Ничего не найдено</div>
                            }
                        </div>


                        <div className="licences" id="licences">
                            <p className="blockName">ЛИЦЕНЗИИ И СЕРТИФИКАТЫ</p>
                            
                            {/* <div className="licencesList"> */}
                            <div className="docsList">
                                {/* {files.licences && year ? files.licences.map((licence, i) => {
                                    return (<div
                                        className="licFile"
                                        key={i}
                                        onLoad={e => {
                                            console.log("MMK", e.target, e.target.parentNode)
                                            // const block = e.target.parentNode;
                                            // block.setAttribute("id", "");
                                            
                                            // setTimeout(() => {
                                            //     block.setAttribute("id", "animate");
                                            // }, i*500);
                                        }}
                                        style={{
                                            background: `url("${licence}")`
                                        }}></div>)
                                }): null} */}
                                {
                                    files.lic_list ? files.lic_list.length > 0 ? files.lic_list.map((file, i) => {
                                        return (
                                            <DocsElem i={i} file={file} type={"licences"} />
                                        )
                                    }) : <div>Ничего не найдено</div>
                                    : <div>Ничего не найдено</div>
                                }
                            </div>
                        </div>
                    </div>
                </div>
                <KTRVFooter />
            </div>
        </div>
    )
})