import React from "react";
// import { KTRVHeader, KTRVFooter } from "features/organisms/";
import { LeftMenu, OAOLine, KTRVFooter } from "features/organisms";
import "./styles.scss";
import { useParams } from "react-router-dom";
import { getNews } from "features/common/api";
import { $currentNews } from "features/common/model";
import { useStore } from "effector-react";
import { useNavigate } from "react-router-dom";

export const NewsShow = (props) => {
    const params = useParams();
    const history = useNavigate();

    const newsData = useStore($currentNews);
    
    React.useState(() => {
        // console.log("MOUNT 2");
        getNews(params.id);
    }, [params]);

    console.log("NEWS", newsData);

    return Object.keys(newsData).length > 0 ? (
        <div className="contentInfo">
            <OAOLine/>
            <div className="contentData">
                <LeftMenu/>
                <div className="data">
                    <div>
                        <div
                            className="blockName back"
                            // onClick={() => history.push("/about")}
                            onClick={() => history.goBack()}
                            ><p>{"<"} Назад</p>НОВОСТЬ</div>
                        <div className="newsContentGrid">
                            <div className="newsContent">
                                <p>{newsData.name}</p>
                                <span>Дата добавления : {new Date(newsData.date).toLocaleDateString()}</span>
                                <div className="newsPhoto" style={{
                                    background: `url('${newsData.img}')`
                                }}></div>
                                <div className="newsData" dangerouslySetInnerHTML={{__html: newsData.content}}>
                                    {/* {newsData.content} */}
                                </div>
                            </div>
                        </div>
                    </div>

                    <KTRVFooter />
                </div>
            </div>
        </div>
    ): null;
}