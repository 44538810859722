import React from "react";
import { KTRVFooter } from "features/organisms/";
// import { YMaps, Map } from 'react-yandex-maps';
import "./styles.scss";
import "core-js/stable";
import "regenerator-runtime/runtime";
// import { Link } from "react-router-dom";
// import AnimatedNumber from 'react-animated-number';
// import { Menu } from "features/organisms/";

const YandexMap = React.lazy(() => import('features/modules/ymaps'));
// import YandexM from 'features/modules/ymaps';

export const Homepage = () => {
    const [plus, setPlus] = React.useState(3);
    const [animation, setAnimation] = React.useState({});
    // const [change, setChange] = React.useState(false);
    const [numAnim, setNumAnim] = React.useState(0);
    // const [useAnim, setUseAnim] = React.useState(true);
    // console.log(YandexM.YMaps);
    // const { YMaps, Map } = YandexM;
    // console.log(YandexM);
    // console.log(YMaps, Map);
    // console.log(plus);
    const animate = (type, data, animation) => {
        animation[type] = data ? "animate" : null;
        setAnimation(animation);

        if (type === "map") {
            setTimeout(() => {
                mapAnimate();
            }, 3000);
        }
    }

    const timeAnim = (time, payload) => {
        return new Promise(resolve => {

            setTimeout(() => {
                payload();
                setNumAnim(numAnim + 1);
                resolve();
            }, time);
        })
    }

    const loop = (time, elem) => {
        const block = document.querySelector(elem);

        const stepOne = () => {
            return new Promise(resolve => {
                block.style.marginLeft = "-50%";
                setTimeout(() => {
                    resolve();
                }, time);
            })
        }

        const stepTwo = () => {
            return new Promise(resolve => {
                block.style.marginLeft = "0%";
                setTimeout(() => {
                    resolve();
                }, time);
            })
        }

        const init = async() => {
            console.log("AAA");
            await stepOne();
            await stepTwo();
            init();
        }

        init();
    }

    const mapAnimate = () => {
        let i = plus;
        let step = 5;
        
        const mapStep = () => {
            return new Promise(async resolve => {
                // await timeAnim(500, () => animate("whiteBox", false, animation));
                document.querySelector(".whiteBox").setAttribute("id", "animate");
                setTimeout(() => {
                    resolve();
                }, 1000);
            })
        }

        const showMap = () => {
            return new Promise(async resolve => {
                i += step;
                setPlus(i);
                setTimeout(() => {
                    document.querySelector(".whiteBox").setAttribute("id", "");
                    setTimeout(() => {
                        resolve();
                    }, 1000);
                }, 1000)
            })
        }

        const mapPlus = () => {
            let i = plus;

            const intr = setInterval(() => {
                if (i + 3 < 20) {
                    setPlus(i + 2);
                    i += 3;
                } else {
                    clearInterval(intr);
                }
            }, 2500);
        }

        const init = async() => {
            if (i + step < 20) {
                await mapStep();
                await showMap();
                init();
            }
        }

        init();
    }

    const animationInit = (num) => {
        console.log(animation);
        if (num === 0) timeAnim(500, () => animate("map", true, animation));
        // if (num === 0) timeAnim(200, () => animate("skyCircleImg", true, animation));
        if (num === 0) loop(10000, ".skyCircle img");
        // if (num === 0) timeAnim(100, () => animate("whiteBox", true, animation));

        if (num === 1) timeAnim(0, () => animate("OAO", true, animation));
        if (num === 2) timeAnim(500, () => animate("arz711", true, animation));
        

        if (num === 3) timeAnim(200, () => animate("arz711_p1", true, animation));
        if (num === 4) timeAnim(200, () => animate("arz711_p2", true, animation));
        if (num === 5) timeAnim(200, () => animate("arz711_p3", true, animation));

        // if (num === 6) timeAnim(500, () => animate("whiteBox", false, animation));
    }

    const IntAnimate = ({value, time, step, start}) => {
        value = parseInt(value);
        time = parseInt(time);
        step = step ? step : 1;

        const [sint, setSint] = React.useState(start);

        // React.useEffect(() => {
        //     // console.log(value, time);
        //     const intr = setInterval(() => {
        //         if (value < sint) setSint(sint + 1);
        //     }, time/value, () => {
        //         console.log(123);
        //         clearInterval(intr);
        //     });
        // }, []);

        React.useEffect(() => {
            // let i = start ? start : sint;
            let i = sint;
            // while (i < value) {
            //     console.log(i);
            //     setSint(i);
            //     i += 5;
            // }

            const intr = setInterval(() => {
                if (i <= value) {
                    // console.log(i);
                    setSint(i);
                    i += step;
                }
            }, time/value, () => {
                console.log(123);
                clearInterval(intr);
            })
        }, []);

        return (
            <span>{sint}</span>
        )
        // let i = 0;

        // return (
        //     <span>
        //         {
                    
        //         }
        //     </span>
        // )
    }

    const scrollControl = (elem, width) => {
        document.querySelector(elem).style.marginLeft = -width + "px";

        document.querySelector("body").addEventListener("mousemove", e => {
            const widthA = window.outerWidth;
            const mLeft = parseInt(document.querySelector(elem).style.marginLeft.split("px")[0]);

            // console.log((widthA / width));
            // console.log(e);
            // console.log(e.screenX < widthA / 2);
            // if (mLeft < 0 && mLeft > -300) {
                if (e.screenX < widthA / 2) {
                    if (mLeft < 0) document.querySelector(elem).style.marginLeft = (mLeft + 10) + "px";
                } else {
                    if (mLeft > -width*2) document.querySelector(elem).style.marginLeft = (mLeft - 10) + "px";
                }
            // }
        })
    }

    React.useEffect(() => {
        console.log(numAnim);
        if (numAnim < 6) {
            animationInit(numAnim);
        }
    }, [numAnim]);
    // }, []);

    React.useEffect(() => {
        // scrollControl(".skyCircle img", 50);
        return () => {
            window.timeoutList.map(item => clearTimeout(item));
            window.intervalList.map(item => clearInterval(item));
            console.log("Переход");
        }
    }, []);

    return (
        <>
            <div className="backgroundMap">
                <div className="whiteBox" id={animation.whiteBox}></div>
                {animation.map === "animate" ? <React.Suspense fallback={<div>Loading...</div>}>
                    <YandexMap
                        state={{
                            center: [51.364036, 42.098862],
                            zoom: plus
                        }}
                        width={"100%"}
                        height={"100%"}
                    />
                </React.Suspense>: null}
                <img src="../assets/ktrvLogo3.tiff" className="bigKtrvLogo bigKTRVLogo2" alt="Big KTRV Logo" />

                <div className="skyBlock">
                    <div className="skyContent">
                        <div className="clouds">
                            <img src="../assets/cloud.png"  alt="Cloud" className="cloud cloud1"/>
                            <img src="../assets/cloud.png"  alt="Cloud" className="cloud cloud2"/>
                        </div>
                        <div className="skyCircle">
                            <img id={animation.skyCircleImg} src="../assets/sky.jpg" alt="Sky Circle" />
                        </div>
                    </div>
                </div>
            </div>
            
            <div className="contentHomepage">
                {/* <Menu/> */}
                <img src="../assets/logo.png" alt="Logo"/>
                <div className="overflowAnimate">
                    <span className="OAO" id={animation.OAO}>АКЦИОНЕРНОЕ ОБЩЕСТВО</span>
                    <div className="arz711">
                        <p id={animation.arz711}>
                            {/* <AnimatedNumber
                                duration={10000}
                                stepPrecision={1}
                                value={711}
                                formatValue={perc => parseInt(perc)}
                                /> */}
                            {/* <IntAnimate
                                value={711}
                                time={2000}
                                start={650}
                            /> */}
                            711
                        </p>
                        <div className="arz711-info">
                            <p id={animation.arz711_p1}><span className="blue">А</span></p>
                            <p id={animation.arz711_p2}><span className="blue">Р</span></p>
                            <p id={animation.arz711_p3}><span className="blue">З</span></p>
                        </div>
                    </div>
                </div>
                {/* <Link to="/about" className="button">О ЗАВОДЕ</Link> */}
                
                <div className="arzMiniInfo">
                    <span>Генеральный директор : Хакимов Бехруз Хамидович</span>
                    <span>Главный бухгалтер : Суховерхова Антонина Викторовна</span>
                </div>
            </div>
            <KTRVFooter />
        </>
    )
}