import React from "react";
import './styles.scss';
import { useNavigate, useLocation } from "react-router-dom";

const MenuMem = (props) => {
    // console.log(props);
    const history = useNavigate();
    const location = useLocation();

    const [active, setActive] = React.useState(location.pathname);
    const [mobileActive, setMobileActive] = React.useState(false);

    // console.log(mobileActive);

    React.useEffect(() => {
        // history.listen((location, action) => {
        //     // console.log(location);
        //     // setActive(location.pathname);
        // })
    }, []);
    
    const menuData = [
        {title: "Главная", url: "/"},
        {title: "Информация", url: "/about"},
        {title: "Документы", url: "/docs"},
        {title: "Контакты", url: "/contact"},
        {title: "Z-Новости", url: "/z-news"},
    ];

    // console.log(history);

    return history ? (!props.mobile ? (
        <div className="mainMenu" style={props.style ? props.style : null}>
            {
                menuData.map((item, key) => {
                    return <div key={key} onClick={() => {
                        if (item.url !== active) {
                            history(item.url);
                            setActive(item.url);
                        }
                    }}>
                        {item.title}
                        <div className={`line${item.url === active ? " active" : ""}`}/>
                    </div>
                })
            }
        </div>
    ) : (
        <div className="mobileMenu">
            <div
                onClick={() => setMobileActive(true)}
                className="menuVisual"
            >
                <div></div>
                <div></div>
                <div></div>
            </div>

            { mobileActive ? <div className="menuDisplay">
                <div className="mobileMenuExit" onClick={() => setMobileActive(false)}>x</div>
                {
                    menuData.map((item, key) => {
                        return <div key={key} onClick={() => {
                            setMobileActive(false)
                            if (item.url !== active) {
                                history(item.url);
                                setActive(item.url);
                            }
                        }}>
                            {item.title}
                            <div
                                className={`line${item.url === active ? " active" : ""}`}/>
                        </div>
                    })
                }
            </div> : null }
        </div>
    )
    ): null;
}

export const Menu = React.memo(MenuMem);