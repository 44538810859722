import axios from "axios";
import { useEvent } from "effector-react";
import { setFiles, setCurrentNews, setReqvList, setListNews, setZNews } from "./model";

const API_URL = "https://711arz.ru/api";
// const API_URL = "http://10.4.2.159/711arzBack/api";

export const getFiles = () => 
    axios.get(`${API_URL}/getFiles`).then(res => {
        const setFilesList = useEvent(setFiles);

        setFilesList(res.data.data);
    });

export const getNews = (id) => 
    axios.get(`${API_URL}/getNews/${id}`).then(res => {
        const setCurrentView = useEvent(setCurrentNews);
        setCurrentView(res.data.data);
    });

export const getNewsList = () => 
    axios.get(`${API_URL}/getNews`).then(res => {
        const setNewsList = useEvent(setListNews);

        setNewsList(res.data.data);
    });

export const getZNews = () =>
    axios.get(`${API_URL}/getZNews`).then(res => {
        const setZNewsList = useEvent(setZNews);

        setZNewsList(res.data.data);
    });

export const getReqv = () => 
    axios.get(`${API_URL}/getReqv`).then(res => {
        const setReqv = useEvent(setReqvList);

        setReqv(res.data.data);
    });