import React from "react";
// import { KTRVHeader, KTRVFooter } from "features/organisms/";
import { LeftMenu, OAOLine, KTRVFooter } from "features/organisms";
import "./styles.scss";
import { useNavigate } from "react-router-dom";
import { getNewsList } from "../../common/api";
import { useStore } from "effector-react";
import { $listNews } from "../../common/model";

export const About = React.memo(() => {
    const history = useNavigate();
    const [animation, setAnimation] = React.useState({});
    const [numAnim, setNumAnim] = React.useState(0);
    const [podmenu, setPodmenu] = React.useState("");
    
    const news = useStore($listNews);
    // const rNews = news.reverse();

    console.log("NEWS", news, news.sort((a, b) => parseInt(a.id) < parseInt(b.id)));

    const animate = (type, data, animation) => {
        animation[type] = data ? "animate" : null;
        setAnimation(animation);
    }

    console.log(animation);

    const timeAnim = (time, payload) => {
        return new Promise(resolve => {

            setTimeout(() => {
                payload();
                setNumAnim(numAnim + 1);
                resolve();
            }, time);
        })
    }

    const animationInit = (num) => {
        console.log(animation);
        // if (num === 1) timeAnim(1700, () => animate("showOpacityText", true, animation));
        if (num === 1) timeAnim(0, () => animate("arzInformation", true, animation));

        if (num === 0) timeAnim(0, () => animate("arzWinner", true, animation));
    }

    React.useEffect(() => {
        animationInit(numAnim);
    }, [numAnim]);

    // React.useEffect(() => {
    //     console.log("HISTORY")
    // }, [history])
    // React.useState(() => {
    //     // console.log("MOUNT 2");
    //  }, []);

    // const news = [
    //     {
    //         background: "https://img5.goodfon.ru/original/1920x1080/6/71/peizazh-kholmy-minimalizm.jpg",
    //         name: "711 АРЗ 80 ЛЕТ",
    //         text: "Сегодня нашему предприятию исполняется 80 лет! В честь этого наш директор сделал прикол : расскажи друзьям!",
    //         date: "11.11.2020",
    //     },
    //     {
    //         background: "https://img5.goodfon.ru/original/1920x1080/6/71/peizazh-kholmy-minimalizm.jpg",
    //         name: "711 АРЗ 80 ЛЕТ",
    //         text: "Сегодня нашему предприятию исполняется 80 лет! В честь этого наш директор сделал прикол : расскажи друзьям!",
    //         date: "11.11.2020",
    //     },
    //     {
    //         background: "https://img5.goodfon.ru/original/1920x1080/6/71/peizazh-kholmy-minimalizm.jpg",
    //         name: "711 АРЗ 80 ЛЕТ",
    //         text: "Сегодня нашему предприятию исполняется 80 лет! В честь этого наш директор сделал прикол : расскажи друзьям!",
    //         date: "11.11.2020",
    //     },
    //     {
    //         background: "https://img5.goodfon.ru/original/1920x1080/6/71/peizazh-kholmy-minimalizm.jpg",
    //         name: "711 АРЗ 80 ЛЕТ",
    //         text: "Сегодня нашему предприятию исполняется 80 лет! В честь этого наш директор сделал прикол : расскажи друзьям!",
    //         date: "11.11.2020",
    //     },
    //     {
    //         background: "https://img5.goodfon.ru/original/1920x1080/6/71/peizazh-kholmy-minimalizm.jpg",
    //         name: "711 АРЗ 80 ЛЕТ",
    //         text: "Сегодня нашему предприятию исполняется 80 лет! В честь этого наш директор сделал прикол : расскажи друзьям!",
    //         date: "11.11.2020",
    //     }
    // ]

    const renderNews = news.reverse().map(newsElem => {
        return (
            <div
                className="newsElem"
                style={{
                    background: `url('${newsElem.img}')`,
                    backgroundSize: "cover",
                    backgroundPosition: "center"
                }}
                onClick={() => {
                    history(`/news/${newsElem.id}`)
                }}
                >
                <span>{new Date(newsElem.date).toLocaleDateString()}</span>
                <div className="newsElemInfo">
                    <p className="newsName">{newsElem.name.toUpperCase()}</p>
                    <p className="newsText">{newsElem.content.slice(0, 73) + "..."}</p>
                </div>
            </div>
        )
    })

    const IntAnimate = ({type, value, time, step, start}) => {
        value = parseInt(value);
        time = parseInt(time);
        step = step ? step : 1;

        const [sint, setSint] = React.useState(start);

        React.useEffect(() => {
            let i = sint;

            const intr = setInterval(() => {
                if (i <= value) {
                    if (i + step > value) {
                        setSint(i + (value - i));

                        // console.log(123);
                        clearInterval(intr);

                        animation[type] = value;
                        setAnimation(animation);
                    } else {
                        setSint(i);
                    }
                    i += step;
                }
            }, time/value)
        }, []);

        return (<span>{animation[type] ? animation[type] : sint}</span>)
    }

    const StringAnimate = ({type, value, time}) => {
        const [str, setStr] = React.useState("");
        // const [i, setI] = React.useState(0);
        let i = str.length;

        React.useEffect(() => {
            console.log("MMK", i, str);
            if (animation[type] ? animation[type].length === value.length : false) return;

            const intr = setInterval(() => {
                setStr(value.slice(0, i));
                i += 1;
                
                if (i === value.length + 1) {
                    console.log("AAAAA");
                    clearInterval(intr);
                    animation[type] = value.slice(0, i);
                    setAnimation(animation);
                }
            }, time/value.length);
        }, [type]);

        // React.useEffect(() => {
        //     return () => {
        //         console.log("AAA");
        //         if (animation[type]) {
        //             // if (animation[type].length > i) {
        //             //     animation[type] = value.slice(0, i);
        //             //     // console.log("AAA => ", value.slice(0, i));
        //             //     setAnimation(animation);
        //             // }
        //         } else {
        //             animation[type] = value.slice(0, i);
        //             setAnimation(animation);
        //         }
        //     }
        // }, []);

        return (
            <>{
                animation[type]
                ? (animation[type].length < value.length ? str : animation[type])
                : str
            }</>
        );
    }

    React.useEffect(() => {
        // scrollControl(".skyCircle img", 50);
        Array.from(document.querySelectorAll(".podMenu")).map(elem => {
            elem.style.width = document.querySelector(".data").offsetWidth - 25 - 90 + "px";
        });

        getNewsList();
        
        // console.log("AAA", history.location);
        // if (history.location.hash) {
        //     setPodmenu("prof");
        // }

        return () => {
            window.timeoutList.map(item => clearTimeout(item));
            window.intervalList.map(item => clearInterval(item));
            console.log("Переход");
        }
    }, []);

    return (
        <div className="contentInfo">
            <OAOLine/>
            <div className="contentData">
                <LeftMenu elems={[
                    {name: "Профсоюз", url: "#profUnion", onClick: () => setPodmenu("prof")}
                ]}/>
                <div className="data">
                    <div podMenu={podmenu}>
                        <div
                            className="podMenu"
                            id="podMenuInfo"
                            >
                            <p className="blockName">ИНФОРМАЦИЯ</p>
                            <div className="infoIntLines">
                                <div className="infoInt">
                                    <span>Дата основания</span>
                                    <div className="splitDate">
                                        <div className="splitDateNum">
                                            <p>
                                                <IntAnimate
                                                    type="dayStart"
                                                    value={10}
                                                    time={1000}
                                                    step={3}
                                                    start={0}
                                                />
                                            </p>
                                            <p>0
                                                <IntAnimate
                                                    type="monthStart"
                                                    value={6}
                                                    time={1000}
                                                    step={3}
                                                    start={0}
                                                />
                                            </p>
                                        </div>
                                        <p>
                                            <IntAnimate
                                                type="yearStart"
                                                value={1923}
                                                time={1000}
                                                step={25}
                                                start={0}
                                            />
                                        </p>
                                    </div>
                                </div>

                                <div className="infoInt">
                                    <span>Специального оборудования</span>
                                    <div className="splitDate">
                                        <div className="splitDateStr">
                                            <p>Около</p>
                                        </div>
                                        <p>
                                            <IntAnimate
                                                type="specObor"
                                                value={500}
                                                time={1000}
                                                step={10}
                                                start={0}
                                            />
                                        </p>
                                        <div className="splitDateStr">
                                            <p>единиц</p>
                                        </div>
                                    </div>
                                </div>

                                <div className="infoInt">
                                    <span>Контрольно-измерительной аппаратуры</span>
                                    <div className="splitDate">
                                        <div className="splitDateStr">
                                            <p>Около</p>
                                        </div>
                                        <p>
                                            <IntAnimate
                                                type="specApp"
                                                value={2000}
                                                time={1000}
                                                step={50}
                                                start={0}
                                            />
                                        </p>
                                        <div className="splitDateStr">
                                            <p>единиц</p>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div className="arzContentMore">
                            <div className="arzWinner" id={animation.arzWinner}>
                                {/* <p></p> */}
                                <p>
                                </p>

                                <div className="infoWinner">
                                    <p>
                                    <IntAnimate
                                        type="m2first"
                                        value={2}
                                        time={500}
                                        step={1}
                                        start={0}
                                    />
                                    </p>
                                    <div className="infoWinnerData">
                                        <p>место</p>
                                        <p>в областном смотре-конкурсе на лучшую организацию работы в области охраны труда</p>
                                    </div>
                                </div>

                                <div className="infoWinner">
                                    <p>
                                    <IntAnimate
                                        type="years5second"
                                        value={5}
                                        time={500}
                                        step={2}
                                        start={0}
                                    />
                                    </p>
                                    <div className="infoWinnerData">
                                        <p>лет</p>
                                        <p>является победителем конкурса<br/>"Борисоглебск - цветущий город"</p>
                                    </div>
                                </div>

                                <div className="infoWinner">
                                    <p>
                                    <IntAnimate
                                        type="m2third"
                                        value={2}
                                        time={500}
                                        step={1}
                                        start={0}
                                    />
                                    </p>
                                    <div className="infoWinnerData">
                                        <p>место</p>
                                        <p>за 2010 год в областном конкурсе в номинации "Самая благоустроенная территория предприятия"</p>
                                    </div>
                                </div>
                            </div>

                            <div className="arzInformation" id={animation.arzInformation}>
                                <p><strong>711 АРЗ – градообразующее предприятие</strong>, входящее в число крупнейших налогоплательщиков г. Борисоглебска. Неоднократно занимало призовые места как «Лучший налогоплательщик».</p>
                                <p>Предприятие располагает парком общепромышленного металло – и деревообрабатывающих станков.</p>
                                <p>Завод имеет необходимое количество автотракторной техники для осуществления производственной деятельности.</p>
                                <p>Предприятие согласно договору с ОАО «РЖД» использует железнодорожный путь необщего пользования станции Борисоглебск ЮВЖД. Для организации погрузочно-разгрузочных операций на территории завода имеется высокая платформа.</p>
                            </div>
                        </div>
                        </div>

                        <div
                            className="podMenu"
                            id="podMenuProf"
                            >
                            <div className="profUnion" id="profUnion">
                            {/* <p className="blockName">ПРОФСОЮЗ</p> */}
                            <div
                            className="blockName back"
                            >
                            <p onClick={() => setPodmenu("")}>{"<"} Назад</p>ПРОФСОЮЗ</div>
                            <div className="newsList">
                                {/* <div
                                    className="newsElem"
                                    style={{
                                        background: "url('https://img5.goodfon.ru/original/1920x1080/6/71/peizazh-kholmy-minimalizm.jpg')",
                                        backgroundSize: "cover",
                                        backgroundPosition: "center"
                                    }}
                                    >
                                    <span>11.11.2020</span>
                                    <div className="newsElemInfo">
                                        <p className="newsName">711 АРЗ 80 ЛЕТ</p>
                                        <p className="newsText">Сегодня нашему предприятию исполняется 80 лет! В честь этого наш директор сделал прикол : расскажи друзьям!</p>
                                    </div>
                                </div> */}
                                { renderNews }
                            </div>
                        </div>
                        </div>
                        {/* <div className="newsContent">
                            <p>Название</p>
                            <span>Дата добавления : 11.11.2020</span>
                            <div class="newsData">
                                <p>asfjanjsfansjfnakjsngajnsgjankjgnakjgskanjsngknajngjansgkajsng</p>
                                <p>asfjanjsfansjfnakjsngajnsgjankjgnakjgskanjsngknajngjansgkajsng</p>
                            </div>
                        </div> */}
                    </div>
                </div>

                <KTRVFooter />
            </div>
        </div>
    )
})