import React from "react";
import './styles.scss';

export const OAOLine = (props) => {

    return (
        <div className="OAOLine">
           АКЦИОНЕРНОЕ ОБЩЕСТВО "711 АРЗ"
        </div>
    )
}