import React from "react";
import "./App.scss";
import "./App.mobile.scss";
import "./App.animate.scss";
import { Routes, Router, Route, BrowserRouter, browserHistory } from "react-router-dom";
import { YMInitializer } from "react-yandex-metrika";
import { createBrowserHistory } from 'history';

import { KTRVHeader, KTRVFooter, Menu } from "features/organisms/";
import { Homepage, About, NewsShow, Documents, Contacts, NewsEdit, ZNews } from "./features/pages/";

function App() {
	const [showMenu, setShowMenu] = React.useState(true);
	// console.log("123");

	// if (document !== null) {
	// React.useEffect(() => {
		const changeMenu = (data) => {
			setShowMenu(data);
		}

		document.addEventListener('DOMContentLoaded', e => {
			const showingMenu = (callback) => {
				if (document.querySelector(".contentData .data")) {
					document.querySelector(".contentData .data").addEventListener("scroll", e => {
						// console.log(document.querySelector(".mainMenu").style.opacity);

						if (e.target.scrollTop > 35) {
							changeMenu(false);
						} else {
							changeMenu(true);
						}
					})

					if (callback) callback();
				}
			}

			showingMenu();

			let mutationObserver = new MutationObserver(function(mutations) {
				showingMenu(() => {
					mutationObserver = null;
				});
			  });
			
			  mutationObserver.observe(document.querySelector(".content"), {
				attributes: true,
				characterData: true,
				childList: true,
				subtree: true,
				attributeOldValue: true,
				characterDataOldValue: true
			  });
		});

	React.useEffect(() => {
		window.timeoutList = new Array();
		window.intervalList = new Array();
		
		window.oldSetTimeout = window.setTimeout;
		window.oldSetInterval = window.setInterval;

		setTimeout(() => {
			window.setTimeout = function(code, delay) {
				var retval = window.oldSetTimeout(code, delay);
				window.timeoutList.push(retval);
				return retval;
			};

			window.setInterval = function(code, delay) {
				var retval = window.oldSetInterval(code, delay);
				window.intervalList.push(retval);
				return retval;
			};
		})
	}, []);
	// }, []);
	// }

    return (
        <>
		{/* <Router history={createBrowserHistory()}> */}
			<BrowserRouter>
				<KTRVHeader />
				{ window.outerWidth < 1200 ? <Menu mobile/> : <Menu style={{
					opacity: showMenu ? 1 : 0
				}}/> }
				<div className="content">
					
					<Routes>
					{/* <Route component={Menu}/> */}
					
					
						{/* <Menu/> */}
						<Route exact path="/" element={<Homepage/>}/>
						<Route exact path="/about" element={<About/>}/>
						<Route exact path="/news/:id" element={<NewsShow/>}/>
						<Route exact path="/docs" element={<Documents/>}/>
						<Route exact path="/contact" element={<Contacts/>}/>
						<Route exact path="/z-news" element={<ZNews/>}/>
						<Route exact path="/edit" element={<NewsEdit/>}/>
					</Routes>
					
					</div>
				<YMInitializer accounts={[70952347]} options={{
					clickmap:true,
					trackLinks:true,
					accurateTrackBounce:true,
					webvisor:true
				}}/>
			</BrowserRouter>
			{/* </Router> */}
        </>
    );
}

export default App;
