import React from "react";
// import { KTRVHeader, KTRVFooter } from "features/organisms/";
import { LeftMenu, OAOLine, KTRVFooter } from "../../organisms";
import "./styles.scss";
import { YMaps, Map } from 'react-yandex-maps';
import { useStore } from "effector-react";
import { $reqvList } from "../../common/model";
import { getReqv } from "../../common/api";


const Reqv = ({ reqv, value, styleValue }) => {
    return (
        <div className="reqvElem">
            <p>{reqv}</p>
            <p style={styleValue}>{value}</p>
        </div>
    )
}

export const Contacts = () => {
    // const [year, setYear] = React.useState("2020");
    const reqvList = useStore($reqvList);
    
    React.useState(() => {
        // console.log("MOUNT 2");
        getReqv();
     }, []);

    console.log("REQV", reqvList);

    return Object.keys(reqvList).length > 0 ? (
        <div className="contentInfo">
            <OAOLine/>
            <div className="contentData">
                <LeftMenu/>
                <div className="data">
                    <div>
                        <p className="blockName">КОНТАКТЫ</p>

                        <div className="gridContacts">
                            <div>
                                <div className="contactRow">
                                    <p className="contactHeadText">Телефон/Факс</p>
                                    <div>
                                        <p>Секретарь</p>
                                        <p>8 (47354) 6-69-73</p>
                                        <p>Главный бухгалтер</p>
                                        <p>8 (47354) 6-90-50</p>
                                        <p>ОМТОиС</p>
                                        <p>8 (47354) 6-69-32</p>
                                    </div>
                                </div>

                                <div className="contactRow">
                                    <p className="contactHeadText">Электронная почта</p>
                                    <div>
                                        <p>Общий</p>
                                        <p>arz@711arz.ru</p>
                                        <p>Главный бухгалтер</p>
                                        <p>buh@711arz.ru</p>
                                        <p>ОМТОиС</p>
                                        <div>
                                            <p>omts@711arz.ru</p>
                                            <p>snab@711arz.ru</p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div>
                                <div className="contactRow">
                                    <p className="contactHeadText">Почтовый адрес</p>
                                    <span className="contactMapAdress">397171, г. Борисоглебск Воронежской области ул. Чкалова, дом 18.</span>
                                    <div className="contactMap">
                                        <YMaps>
                                            <Map
                                                state={{
                                                    center: [51.364036, 42.098862],
                                                    zoom: 16
                                                }}
                                                width={"100%"}
                                                // height={"100%"}
                                                />
                                        </YMaps>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className="reqv">
                            <p className="contactHeadText">Реквизиты</p>

                            <div className="reqvContent">
                                {
                                    reqvList.map(item => {
                                        return <Reqv
                                            reqv={item.name}
                                            value={item.value}
                                            styleValue={{
                                                fontSize: item.value.length > 40 ? "13px" : item.value.length <= 20 ? "18px" : "14px"
                                            }}
                                            />
                                    })
                                }
                                {/* <Reqv reqv="Полное наименование предприятия" value="OAO"/>
                                <Reqv reqv="Полное наименование предприятия" value="OAO"/>
                                <Reqv reqv="Полное наименование предприятия" value="OAO"/>
                                <Reqv reqv="Полное наименование предприятия" value="OAO"/>
                                <Reqv reqv="Полное наименование предприятия" value="OAO"/>
                                <Reqv reqv="Полное наименование предприятия" value="OAO"/> */}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <KTRVFooter />
        </div>
    ): null;
}