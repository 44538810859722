import React from "react";
// import { KTRVHeader, KTRVFooter } from "features/organisms/";
import { LeftMenu, OAOLine, KTRVFooter } from "features/organisms";
import "./styles.scss";
import { useStore } from "effector-react";
import { $listZNews } from "features/common/model";
import { getZNews } from "features/common/api";

export const ZNews = React.memo(() => {

    const zNewsList = useStore($listZNews);

    React.useEffect(() => {
        getZNews();
    }, []);
        return (
            <div className="contentInfo">
            <OAOLine/>
                <div className="contentData">
                    <LeftMenu elems={[
                        {name: "Z-Новости", url: "#zNews"}
                    ]}/>
                    <div className="data">
                        <div>
                            <p className="blockName">Z-Новости</p>
                            <div className="news">
                                {
                                    zNewsList.map((item) => {
                                        return(
                                            <a 
                                                className="newsItem" 
                                                key={item.href} 
                                                href={item.href}
                                                target="_blank"
                                                rel="noreferrer"
                                            >
                                                <img src={item.img} alt={item.name}/>
                                                <p>{item.date}</p>
                                                <p>{item.name}</p>
                                            </a>
                                        )
                                    })
                                }
                            </div>
                        </div>
                    </div>
                <KTRVFooter />
                </div>
            </div>
        );
});