import { createStore, createEvent } from "effector";

export const $files = createStore({});
export const $currentNews = createStore({});
export const $listNews = createStore([]);
export const $reqvList = createStore({});
export const $listZNews = createStore([]);

export const setFiles = createEvent();
export const setCurrentNews = createEvent();
export const setListNews = createEvent();
export const setReqvList = createEvent();
export const setZNews = createEvent();

$files
    .on(setFiles, (_, payload) => payload);

$currentNews
    .on(setCurrentNews, (_, payload) => payload);

$listNews
    .on(setListNews, (_, payload) => payload);

$reqvList
    .on(setReqvList, (_, payload) => payload);

$listZNews
    .on(setZNews, (_, payload) => payload);

$files.watch((_, payload) => {
    console.log(_, payload);
})