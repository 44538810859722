import React from "react";
import './styles.scss';
import { Link } from "react-router-dom";

export const LeftMenu = (props) => {
    const [mobile, setMobile] = React.useState(!(window.outerWidth > 1200));
    const scrollView = (element) => {
        document.querySelector(element).scrollIntoView({
            behavior: 'smooth',
            block: 'start'
        })
    }

    return (
        <div
            style={{
                padding: mobile ? 0 : "25px 70px",
                width: mobile ? "35px" : ""
            }}
            className={`leftContent${!mobile ? " active" : ""}`}
            >
            {mobile ? (
                <div className="openPodMenu" onClick={() => setMobile(false)}>Дополнительно</div>
            ) : (
            <div className="fixedPosition">
                <div className="arzBlock">
                    <p
                        onClick={() => setMobile(true)}
                        className="closePodMenu"
                    >{"< "}Назад</p>
                    <span className="OAO">АКЦИОНЕРНОЕ ОБЩЕСТВО</span>
                    <div className="arz711">
                        <div className="splitInfo">
                            <p>711</p>
                            <img src="/assets/logo.png" alt="Logo"/>
                        </div>
                        <div className="arz711-info">
                            <p><span className="blue">А</span>ВИАЦИОННЫЙ</p>
                            <p><span className="blue">Р</span>ЕМОНТНЫЙ</p>
                            <p><span className="blue">З</span>АВОД</p>
                        </div>
                    </div>
                </div>

                <div className="contextMenu">
                    { props.elems ? props.elems.map(item => {
                        return <Link
                            to={item.url}
                            onClick={() => {
                                if (item.onClick) {
                                    item.onClick()
                                } else {
                                    scrollView(item.url)
                                }
                            }}
                            >{item.name}</Link>
                    }) : null}
                </div>
            </div>
            )}
        </div>
    )
}