import React from "react";
import JoditEditor from "jodit-react";

export const NewsEdit = () => {
    // const [value, setValue] = React.useState("");

    // return (
    //     <div>
    //         <RichTextEditor
    //             value={value}
    //             onChange={value => setValue({value})}
    //         />
    //     </div>
    // )

    const editor = React.useRef(null)
	const [content, setContent] = React.useState('')
	
	const config = {
		readonly: false // all options from https://xdsoft.net/jodit/doc/
	}
	
	return (
        <JoditEditor
        	ref={editor}
            value={content}
            config={config}
		    tabIndex={1} // tabIndex of textarea
		    onBlur={newContent => setContent(newContent.target.innerHTML)} // preferred to use only this option to update the content for performance reasons
            onChange={newContent => console.log(newContent)}
        />
    );
}